<template>
  <CLoading v-if="isLoading" />
  <CNavigation />
</template>

<script>
import CNavigation from "./components/CNavigation.vue";

import CLoading from "@/components/CLoading.vue";

export default {
  data() {
    return {
      showMenu: false,
      isLoading: true,
    };
  },
  components: {
    CNavigation,
    CLoading,
  },
  mounted() {
    setTimeout(() => {
   
      this.isLoading = false;
    }, 1500);
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

#app {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  height: 100%;
  /* background-color: #170e32; */
  background: linear-gradient(
    13deg,
    rgba(23, 14, 50, 1) 57%,
    rgba(83, 27, 137, 1) 100%
  );
}

nav {
  padding: 30px;
}

nav a {
  font-weight: 900;
  color: white;
}

nav a.router-link-exact-active {
  color: #00c9a2;
  border: 0px;
  border-bottom: 5px solid #c6b5e6;

  border-radius: 5px;
}
</style>
