<template>
  <div
    class="my-2 cursor-default text-center border-solid border-2 rounded-2xl border-transparent px-4 w-fit text-white hover:scale-105 duration-300 hover:-translate-y-0.5"
    :class="this.color"
  >
    {{this.label}}
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    color: String
  },
};
</script>
