/* eslint-disable */
<template>
  <div>
    <div
      class="px-8 xl:px-64 flex flex-col sm:flex-row sm:place-content-between sm:items-center gap-64 sm:gap-0 h-full"
    >
      <div>
        <div class="z-5 top-0 sticky m-auto">
          <p class="font-bold text-5xl text-center">Laurent Brand</p>
          <div
            v-motion
            :initial="{
              x: 0,
            }"
            :enter="{
              x: 53,
              transition: {
                duration: 3000,
                repeat: Infinity,
                repeatType: 'mirror',
              },
            }"
            class="w-min h-min font-thin text-2xl text-center flex m-auto"
          >
            <VueWriter
              :array="arr"
              :eraseSpeed="50"
              :typeSpeed="60"
              :delay="4900"
              :start="500"
              :caret="cursor"
            />
            developer
          </div>
        </div>

        <div class="flex md:items-center mt-12">
          <div class="text-justify">
            <p class="text-3xl font-bold mb-2">Hey 👋</p>
            <p class="leading-loose lg:w-5/6">
              I'm <b>Laurent Brand</b>, <b>{{ age }}</b> years old. After
              graduating high school in Luxembourg with mathematics and
              informatics as my main subjects, I'm currently studying computer
              science at the <b>Trier University of Applied Sciences</b>.
              Already at a young age, I developed an interest in computers, and
              even got into coding when I turned 14. I've already built and
              programmed apps, websites, and scripts. In the future, I'm
              interested in deepening my knowledge of AI, or more specifically,
              Deep Learning.
            </p>
          </div>
          <div class="overflow-hidden invisible lg:visible lg:w-full w-0">
            <p
              class="font-bold cursor-pointer"
            >
              currently working on <a target="_blank" href="https://www.jempi.app/" class="text-blue-400">jempi.app</a> 
            </p>
            <img
              v-motion-wiggle
              src="@/assets/memoji.png"
              class="z-10"
              alt=""
              @click="goToDino"
            />
            <p
              class="font-bold cursor-pointer"
              onclick="location.href='mailto:contact@laurentbrand.com'"
            >
              contact@laurentbrand.com
            </p>
          </div>
        </div>
        <div class="mt-20">
          <p
            style="
              border: 0px;
              border-bottom: 5px solid #c6b5e6;

              border-radius: 5px;
            "
            class="text-3xl text-left font-bold w-min"
          >
            Technologies
          </p>

          <div class="my-8 m-auto gap-2 flex overflow-x-scroll">
            <CTechnologyBubble label="Java" color="bg-orange-500" />
            <CTechnologyBubble label="Python" color="bg-blue-400" />
            <CTechnologyBubble label="Django" color="bg-green-800" />
            <CTechnologyBubble label="Javascript" color="bg-yellow-500" />
            <CTechnologyBubble label="Flutter" color="bg-blue-500" />
            <CTechnologyBubble label="HTML" color="bg-orange-800" />
            <CTechnologyBubble label="CSS" color="bg-blue-800" />
          </div>
        </div>
        <div class="mt-20">
          <p
            style="
              border: 0px;
              border-bottom: 5px solid #c6b5e6;

              border-radius: 5px;
            "
            class="text-3xl text-left font-bold w-min"
          >
            Languages
          </p>

          <div class="my-8 m-auto gap-2 flex overflow-x-scroll">
            <CTechnologyBubble label="German" color="bg-orange-500" />
            <CTechnologyBubble label="English" color="bg-blue-400" />
            <CTechnologyBubble label="French" color="bg-green-800" />
            <CTechnologyBubble label="Luxembourgish" color="bg-yellow-500" />
          </div>
        </div>

        <div class="mt-20">
          <p
            style="
              border: 0px;
              border-bottom: 5px solid #c6b5e6;

              border-radius: 5px;
            "
            class="text-3xl text-left font-bold w-fit"
          >
            Operating Systems
          </p>

          <div class="my-8 m-auto gap-2 flex overflow-x-scroll">
            <CTechnologyBubble label="MacOS" color="bg-[#680FC9]" />
            <CTechnologyBubble label="Windows 10" color="bg-[#0179D7]" />
            <CTechnologyBubble label="Debian" color="bg-[#A8002F]" />
          </div>
        </div>
        <div class="mt-20">
          <p
            style="
              border: 0px;
              border-bottom: 5px solid #c6b5e6;

              border-radius: 5px;
            "
            class="text-3xl text-left font-bold w-min"
          >
            Diploma
          </p>

          <div class="mb-20">
            <div class="my-8">
              <p class="text-xl text-left font-bold mb-2 underline">
                General Certificate of Secondary Education - Advanced Level
              </p>
              <table class="mt-8 text-left">
                <tr>
                  <td class="font-bold">School</td>
                  <td class="font-thin">Lycée des Arts et Métiers</td>
                </tr>
                <tr>
                  <td class="font-bold">Degree</td>
                  <td class="font-thin">Diplôme de fin d'études secondaires</td>
                </tr>
                <tr>
                  <td class="font-bold">Subjects</td>
                  <td class="font-thin">Mathematics, Informatics</td>
                </tr>
                <tr>
                  <td class="font-bold pr-4">Graduation</td>
                  <td class="font-thin">June 2022</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CTechnologyBubble from "@/components/CTechnologyBubble.vue";

export default {
  name: "HomeView",
  components: {
    CTechnologyBubble,
  },
  data() {
    return { arr: ["Software", "Web", "App"] };
  },
  methods: {
    goToDino() {
      this.$router.push("/dino");
    },
  },
  // calculate age
  computed: {
    age() {
      var birthDate = new Date('2003-03-04');
      var currentDate = new Date();

      var age = currentDate.getFullYear() - birthDate.getFullYear();

      // Check if the current month and day are before your birthdate
      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
  },


};
</script>
